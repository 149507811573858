import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import PageLayout from "examples/LayoutContainers/PageLayout";
import img from "../../assets/images/logos/Soulpizza.png";
import { validate, handleChange } from "../../lib/joiHelper";
import { emailSignInSchema, usernameSignInSchema } from "./schema";
import { useLoginMutation } from "../../services/sign-in.service";
import { useCurrentUrl } from "../../CurrentUrlContext";
import Loader from "../../components/Loader";

function SignIn() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fieldObj = {
    password: "",
  };

  const [data, setData] = useState({ ...fieldObj });
  const [error, setError] = useState({ ...fieldObj });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { isSuperAdmin } = useCurrentUrl();

  const [loginTrigger] = useLoginMutation();

  const onChangeHandler = (e) => {
    data[e.target.name] = e.target.value;
    setData({ ...data });
  };
  const schema = isSuperAdmin ? usernameSignInSchema(t) : emailSignInSchema(t);

  const onBlurHandler = (event) => {
    const { dataClone, errorClone } = handleChange(event, schema, data, error);
    setData(dataClone);
    setError(errorClone);
  };

  const handleSubmit = async () => {
    const errorclone = validate(data, schema);
    if (errorclone) {
      setError(errorclone);
    } else {
      setError("");
      // call server
      setLoading(true);
      const result = await loginTrigger(data);
      setLoading(false);
      if ("error" in result) {
        toast.error(result?.error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
      } else if (result?.data?.token) {
        localStorage.setItem("authToken", result?.data?.token);
        localStorage.setItem("userId", result?.data?.user_id);
        navigate("/stores");
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <PageLayout>
      <MDBox position="absolute" width="100%" minHeight="100vh" />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
            <Card>
              <MDBox
                variant="gradient"
                borderRadius="lg"
                mx={2}
                mt={1}
                p={2}
                mb={1}
                textAlign="center"
              >
                <img src={img} sizes="small" sx={{ width: 100, height: 100 }} alt="logo" />
              </MDBox>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    {!isSuperAdmin ? (
                      <TextField
                        id="outlined-email-input"
                        autoComplete="current-email"
                        type="email"
                        name="email"
                        fullWidth
                        label={t("signIn:EMAIL")}
                        error={error?.email?.length > 0}
                        helperText={error?.email}
                        onChange={onChangeHandler}
                        onBlur={onBlurHandler}
                      />
                    ) : (
                      <TextField
                        id="outlined-text-input"
                        autoComplete="current-text"
                        type="text"
                        name="username"
                        fullWidth
                        label={t("signIn:USER_NAME")}
                        error={error?.username?.length > 0}
                        helperText={error?.username}
                        onChange={onChangeHandler}
                        onBlur={onBlurHandler}
                      />
                    )}
                  </MDBox>
                  <TextField
                    id="outlined-password-input"
                    autoComplete="current-password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    fullWidth
                    label={t("signIn:PASSWORD")}
                    error={error?.password?.length > 0}
                    helperText={error?.password}
                    onChange={onChangeHandler}
                    value={data?.password}
                    onBlur={onBlurHandler}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <MDBox mt={4} mb={1}>
                    <MDButton variant="gradient" color="info" onClick={handleSubmit} fullWidth>
                      {t("signIn:SIGN_IN")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Loader open={loading} />
    </PageLayout>
  );
}

export default SignIn;
