import React, { useCallback, useEffect, useState } from "react";
import { Card, Grid, Icon, Switch, TablePagination } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import MDBox from "components/MDBox";
import { useTranslation } from "react-i18next";
import MDTypography from "components/MDTypography";
// import MDAvatar from "components/MDAvatar";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";

import PreviewImage from "components/PreviewImage";
import DataTable from "../../examples/Tables/DataTable";
import {
  useLazyGetProductsQuery,
  useUpdateProductMutation,
  useLazyDeleteProductQuery,
} from "../../services/product.service";
import Loader from "../../components/Loader";
import Constants from "../../lib/Constants";
import TableActions from "../../components/TableActions";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { isSuperAdmin } from "../../lib/helperFuctions";

const renderPriceColumn = (product, country) => {
  if (
    product?.productCategory?.name?.toLowerCase() !==
    Constants.PRODUCT_CATEGORY.PIZZA?.toLowerCase()
  ) {
    const price = product?.price?.find((item) => item?.country === country)?.price;
    return (
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {price ? `$${price}` : "-"}
      </MDTypography>
    );
  }
  const price = product?.price?.find((item) => item?.country === country)?.price;
  if (!price)
    return (
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        -
      </MDTypography>
    );
  return (
    <>
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {price?.small ? `S - $${price?.small}` : "-"}
      </MDTypography>
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {price?.large ? `L - $${price?.large}` : "-"}
      </MDTypography>
    </>
  );
};

function Products() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [products, setProducts] = useState({
    total: 0,
    data: [],
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteProductId, setDeleteProductId] = useState("");
  const [isInitialRender, setIsInitialRender] = useState(true);

  const [getProducts] = useLazyGetProductsQuery();
  const [updateProductTrigger] = useUpdateProductMutation();
  const [deleteProduct] = useLazyDeleteProductQuery();

  const fetchProducts = useCallback(async (load) => {
    setLoading(load);
    const { data, isSuccess, isError, error } = await getProducts({
      search,
      page: page + 1,
      limit: rowsPerPage,
    });

    if (isSuccess) {
      setProducts(data);
    }

    if (isError) {
      console.log(error);
    }
    setLoading(false);
  });

  useEffect(() => {
    fetchProducts(true);
  }, []);

  useEffect(() => {
    fetchProducts(isInitialRender);
    if (isInitialRender) {
      setIsInitialRender(false);
    }
  }, [search, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));

    setPage(0);
  };

  const handleEnableDisable = async (productId, productStatus) => {
    const updatedStatus =
      productStatus === Constants.PRODUCT_STATUS.ENABLED
        ? Constants.PRODUCT_STATUS.DISABLED
        : Constants.PRODUCT_STATUS.ENABLED;

    const productsClone = { ...products };

    productsClone.data = productsClone?.data?.map((product) => {
      if (product?.id === productId) {
        product = { ...product, status: updatedStatus };
      }
      return product;
    });
    setProducts(productsClone);
    const submitData = new FormData();
    submitData.append("status", updatedStatus);
    const result = await updateProductTrigger({
      submitData: isSuperAdmin() ? submitData : { status: updatedStatus },
      id: productId,
    });
    if ("error" in result) {
      toast.error(result?.error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
    } else {
      toast.success(result?.data?.msg);
    }
  };

  const editProductHandler = (id) => {
    navigate(`edit/${id}`, { state: { pageTitle: t("Product:EDIT_PRODUCT"), pageType: "edit" } });
  };

  const viewProductHandler = (id) => {
    navigate(`view/${id}`, { state: { pageTitle: t("Product:VIEW_PRODUCT"), pageType: "view" } });
  };

  const handleDialogOpen = (id) => {
    setDeleteProductId(id);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = async () => {
    setDialogOpen(false);
    const { data, isSuccess, isError, error, isLoading } = await deleteProduct(deleteProductId);
    setLoading(isLoading);
    if (isSuccess) {
      toast.success(data?.msg);
      fetchProducts(true);
    }
    if (isError) {
      toast.error(error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
    }
  };

  const renderTableAction = (product) => {
    return (
      <TableActions
        record={product}
        onEdit={editProductHandler}
        onView={viewProductHandler}
        onDelete={handleDialogOpen}
      />
    );
  };

  const columns = [
    { Header: t("common:IMAGE"), accessor: "image", width: "15%", align: "left" },
    { Header: t("common:NAME"), accessor: "name", width: "30%", align: "left" },
    { Header: t("common:CATEGORY"), accessor: "category", width: "10%", align: "left" },
    { Header: t("product:NZ_PRICE"), accessor: "nz_price", align: "center" },
    { Header: t("product:AUS_PRICE"), accessor: "aus_price", align: "center" },
    { Header: t("common:ENABLE"), accessor: "enable", align: "center" },
    ...(isSuperAdmin()
      ? [
          {
            Header: t("common:ACTION"),
            accessor: "action",
            align: "center",
            Cell: ({ row }) => {
              return renderTableAction({ id: row?.original?.id?.props?.children });
            },
          },
        ]
      : []),
  ];

  const rows = products?.data?.map((product) => ({
    id: <span>{product?.id}</span>,
    image: (
      <div style={{ width: "60px", height: "60px" }}>
        <PreviewImage src={product.image} alt={product.name} />
      </div>
    ),
    name: (
      <MDTypography variant="caption" fontWeight="medium" style={{ color: product?.label_color }}>
        {product?.name}
      </MDTypography>
    ),
    category: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {product?.productCategory?.name}
      </MDTypography>
    ),
    nz_price: <MDBox>{renderPriceColumn(product, Constants.COUNTRY_OBJ.NZ)}</MDBox>,
    aus_price: <MDBox>{renderPriceColumn(product, Constants.COUNTRY_OBJ.AUS)}</MDBox>,
    enable: (
      <Switch
        inputProps={{ "aria-label": "Enable Product" }}
        value="1"
        color="warning"
        checked={product?.status === Constants.PRODUCT_STATUS.ENABLED}
        onChange={() => handleEnableDisable(product?.id, product?.status)}
      />
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ConfirmationDialog
        title={t("product:DELETE_PRODUCT")}
        content={t("common:ARE_YOU_SURE_YOU_WANT_TO_DELETE")}
        confirmText={t("common:CONFIRM")}
        cancelText={t("common:CANCEL")}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleConfirm}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  {t("product:PRODUCTS")}
                </MDTypography>
                {isSuperAdmin() && (
                  <Link to="/products/add">
                    <MDButton color="white" size="small">
                      <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      &nbsp;{t("product:ADD_PRODUCT")}
                    </MDButton>
                  </Link>
                )}
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted
                  canSearch
                  onSearchHandler={(value) => setSearch(value)}
                  noEndBorder
                />
                <TablePagination
                  rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPTIONS}
                  component="div"
                  count={products?.total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Loader open={loading} />
    </DashboardLayout>
  );
}

export default Products;
