import baseApiService from "./base-api.service";

const STATECITY_TAG = "STATECITY";

/**
 * Service to fetch data from the API for an endpoint.
 */
const StatesCitiesApi = baseApiService
  .enhanceEndpoints({
    addTagTypes: [STATECITY_TAG],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getStates: build.query({
        query: (data) => ({
          url: `/states?country=${data.country}`,
          method: "GET",
        }),
      }),
      getCities: build.query({
        query: (data) => ({
          url: `/cities?state=${data.state}&country=${data.country}`,
          method: "GET",
        }),
      }),
    }),
    overrideExisting: false,
  });

export const { useLazyGetCitiesQuery, useLazyGetStatesQuery } = StatesCitiesApi;
