export default {
  ingredients: [
    {
      label: "Pepperoni",
      value: "Pepperoni",
    },
    {
      label: "Mushroom",
      value: "Mushroom",
    },
    {
      label: "Extra cheese",
      value: "Extra cheese",
    },
    {
      label: "Sausage",
      value: "Sausage",
    },
    {
      label: "Onion",
      value: "Onion",
    },
    {
      label: "Black olives",
      value: "Black olives",
    },
    {
      label: "Green pepper",
      value: "Green pepper",
    },
    {
      label: "Fresh garlic",
      value: "Fresh garlic",
    },
    {
      label: "Tomato",
      value: "Tomato",
    },
    {
      label: "Fresh basil",
      value: "Fresh basil",
    },
    {
      label: "Paneer",
      value: "Paneer",
    },
    {
      label: "Capsicum",
      value: "Capsicum",
    },
    {
      label: "Mayo",
      value: "Mayo",
    },
    {
      label: "Feta",
      value: "Feta",
    },
    {
      label: "Chilly Flakes",
      value: "Chilly Flakes",
    },
    {
      label: "Ginger",
      value: "Ginger",
    },
    {
      label: "Coriander",
      value: "Coriander",
    },
    {
      label: "Olives",
      value: "Olives",
    },
    {
      label: "Black Pepper",
      value: "Black Pepper",
    },
    {
      label: "Red Onion",
      value: "Red Onion",
    },
    {
      label: "Peri Peri Sauce",
      value: "Peri Peri Sauce",
    },
    {
      label: "Chilli Base Sauce",
      value: "Chilli Base Sauce",
    },
    {
      label: "Sweet Corns",
      value: "Sweet Corns",
    },
    {
      label: "Oregano",
      value: "Oregano",
    },
    {
      label: "Soul Makhni Sauce",
      value: "Soul Makhni Sauce",
    },
    {
      label: "Pineapple",
      value: "Pineapple",
    },
    {
      label: "Spring Onion",
      value: "Spring Onion",
    },
    {
      label: "Prawn",
      value: "Prawn",
    },
  ],

  products: [
    {
      name: "Double Cheese Margherita",
      label_color: "#0000FF",
      image: "Double_Cheese_Margherita.jpg",
      category: "Pizza",
      price: "100",
      id: 1,
    },
    {
      name: "Chicken Pizza",
      label_color: "#FF0000",
      image: "meat-pizza-chicken.jpg",
      category: "Pizza",
      price: "150",
      id: 2,
    },
  ],
  stores: [
    {
      id: 1,
      name: "Soul Pizza 1",
      owner_name: "John Doe",
      email: "john@soulpizza.com",
      phone: "+1903990390",
      pincode: "985621",
      createdAt: "2023-03-15T13:54:52.760Z",
    },
    {
      id: 2,
      name: "Soul Pizza 2",
      owner_name: "Clay Johnson",
      email: "clay@soulpizza.com",
      phone: "+1903990590",
      pincode: "985622",
      createdAt: "2023-03-17T13:54:52.760Z",
    },
  ],
  ordersList: [
    {
      orderId: "#1234",
      date: "26 March 2020",
      customerName: "David Horison",
      address: "32 The Green London",
      amount: "$150",
      status: "Delivered",
      orderItems: [
        {
          productName: "Pizza",
          quantity: 1,
          ingredients:
            "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato,Cheese, Tomato, Onion, Cheese, Tomato",
          amount: "$50",
        },
        {
          productName: "Pizza",
          quantity: 2,
          ingredients: "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese",
          amount: "$100",
        },
      ],
    },
    {
      orderId: "#1234",
      date: "26 March 2020",
      customerName: "David Horison",
      address: "32 The Green London",
      amount: "$150",
      status: "In Progress",
      orderItems: [
        {
          productName: "Pizza",
          quantity: 1,
          ingredients:
            "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato,Cheese, Tomato, Onion, Cheese, Tomato",
          amount: "$50",
        },
        {
          productName: "Pizza",
          quantity: 2,
          ingredients: "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese",
          amount: "$100",
        },
      ],
    },
    {
      orderId: "#1234",
      date: "26 March 2020",
      customerName: "David Horison",
      address: "32 The Green London",
      amount: "$150",
      status: "Delivered",
      orderItems: [
        {
          productName: "Pizza",
          quantity: 1,
          ingredients:
            "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato,Cheese, Tomato, Onion, Cheese, Tomato",
          amount: "$50",
        },
        {
          productName: "Pizza",
          quantity: 2,
          ingredients: "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese",
          amount: "$100",
        },
      ],
    },
    {
      orderId: "#1234",
      date: "26 March 2020",
      customerName: "David Horison",
      address: "32 The Green London",
      amount: "$150",
      status: "Delivered",
      orderItems: [
        {
          productName: "Pizza",
          quantity: 1,
          ingredients:
            "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato,Cheese, Tomato, Onion, Cheese, Tomato",
          amount: "$50",
        },
        {
          productName: "Pizza",
          quantity: 2,
          ingredients: "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese",
          amount: "$100",
        },
      ],
    },
    {
      orderId: "#1234",
      date: "26 March 2020",
      customerName: "David Horison",
      address: "32 The Green London",
      amount: "$150",
      status: "In Progress",
      orderItems: [
        {
          productName: "Pizza",
          quantity: 1,
          ingredients:
            "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato,Cheese, Tomato, Onion, Cheese, Tomato",
          amount: "$50",
        },
        {
          productName: "Pizza",
          quantity: 2,
          ingredients: "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese",
          amount: "$100",
        },
      ],
    },
    {
      orderId: "#1234",
      date: "26 March 2020",
      customerName: "David Horison",
      address: "32 The Green London",
      amount: "$150",
      status: "Delivered",
      orderItems: [
        {
          productName: "Pizza",
          quantity: 1,
          ingredients:
            "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato,Cheese, Tomato, Onion, Cheese, Tomato",
          amount: "$50",
        },
        {
          productName: "Pizza",
          quantity: 2,
          ingredients: "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese",
          amount: "$100",
        },
      ],
    },
    {
      orderId: "#1234",
      date: "26 March 2020",
      customerName: "David Horison",
      address: "32 The Green London",
      amount: "$150",
      status: "Delivered",
      orderItems: [
        {
          productName: "Pizza",
          quantity: 1,
          ingredients:
            "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato,Cheese, Tomato, Onion, Cheese, Tomato",
          amount: "$50",
        },
        {
          productName: "Pizza",
          quantity: 2,
          ingredients: "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese",
          amount: "$100",
        },
      ],
    },
    {
      orderId: "#1234",
      date: "26 March 2020",
      customerName: "David Horison",
      address: "32 The Green London",
      amount: "$150",
      status: "Delivered",
      orderItems: [
        {
          productName: "Pizza",
          quantity: 1,
          ingredients:
            "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato,Cheese, Tomato, Onion, Cheese, Tomato",
          amount: "$50",
        },
        {
          productName: "Pizza",
          quantity: 2,
          ingredients: "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese",
          amount: "$100",
        },
      ],
    },
    {
      orderId: "#1234",
      date: "26 March 2020",
      customerName: "David Horison",
      address: "32 The Green London",
      amount: "$150",
      status: "Delivered",
      orderItems: [
        {
          productName: "Pizza",
          quantity: 1,
          ingredients:
            "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato,Cheese, Tomato, Onion, Cheese, Tomato",
          amount: "$50",
        },
        {
          productName: "Pizza",
          quantity: 2,
          ingredients: "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese",
          amount: "$100",
        },
      ],
    },
    {
      orderId: "#1234",
      date: "26 March 2020",
      customerName: "David Horison",
      address: "32 The Green London",
      amount: "$150",
      status: "Delivered",
      orderItems: [
        {
          productName: "Pizza",
          quantity: 1,
          ingredients:
            "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato,Cheese, Tomato, Onion, Cheese, Tomato",
          amount: "$50",
        },
        {
          productName: "Pizza",
          quantity: 2,
          ingredients: "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese",
          amount: "$100",
        },
      ],
    },
  ],

  userLists: [
    {
      username: "John Michael",
      email: "john@creative-tim.com",
      phone: "6594715568",
      address: "zinipol chachariya bhadra patan",
      id: 1,
      disable: false,
    },
    {
      username: "Alexa Liras",
      email: "alexa@creative-tim.com",
      phone: "23-98-6547",
      address: "chindiya darvaja bahar patan",
      id: 2,
      disable: false,
    },
    {
      username: "Laurent Perrier",
      email: "laurent@creative-tim.com",
      phone: "6594715568",
      address: "zinipol chachariya pase patan",
      id: 3,
      disable: false,
    },
  ],
  trnasactions: [
    {
      transactionId: "#1234",
      date: "26 March 2020",
      customerName: "David Horison",
      address: "32 The Green London",
      amount: "$150",
      status: "Success",
      orderItems: [
        {
          productName: "Pizza",
          quantity: 1,
          ingredients:
            "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato,Cheese, Tomato, Onion, Cheese, Tomato",
          amount: "$50",
        },
        {
          productName: "Pizza",
          quantity: 2,
          ingredients: "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese",
          amount: "$100",
        },
      ],
    },
    {
      transactionId: "#1234",
      date: "26 March 2020",
      customerName: "David Horison",
      address: "32 The Green London",
      amount: "$150",
      status: "Failed",
      orderItems: [
        {
          productName: "Pizza",
          quantity: 1,
          ingredients:
            "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato,Cheese, Tomato, Onion, Cheese, Tomato",
          amount: "$50",
        },
        {
          productName: "Pizza",
          quantity: 2,
          ingredients: "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese",
          amount: "$100",
        },
      ],
    },
    {
      transactionId: "#1234",
      date: "26 March 2020",
      customerName: "David Horison",
      address: "32 The Green London",
      amount: "$150",
      status: "Failed",
      orderItems: [
        {
          productName: "Pizza",
          quantity: 1,
          ingredients:
            "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato,Cheese, Tomato, Onion, Cheese, Tomato",
          amount: "$50",
        },
        {
          productName: "Pizza",
          quantity: 2,
          ingredients: "Tomato, Onion, Cheese, Tomato, Onion, Cheese, Tomato, Onion, Cheese",
          amount: "$100",
        },
      ],
    },
  ],
  productCategories: [
    {
      name: "Pizza",
      image: "https://picsum.photos/200",
    },
    {
      name: "Wings",
      image: "https://picsum.photos/200",
    },
    {
      name: "Pasta",
      image: "https://picsum.photos/200",
    },
  ],
};
