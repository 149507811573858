import baseApiService from "./base-api.service";

const USER_TAG = "USER";

/**
 * Service to fetch data from the API for an endpoint.
 */
const UserApi = baseApiService
  .enhanceEndpoints({
    addTagTypes: [USER_TAG],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getUser: build.query({
        query: (params) => ({
          url: "/users",
          params: params,
        }),
        providesTags: [USER_TAG],
      }),
    }),
    overrideExisting: false,
  });

export const { useLazyGetUserQuery } = UserApi;
