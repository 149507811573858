import baseApiService from "./base-api.service";

const STORE_TAG = "STORE";

/**
 * Service to fetch data from the API for an endpoint.
 */
const StoreApi = baseApiService
  .enhanceEndpoints({
    addTagTypes: [STORE_TAG],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getStores: build.query({
        query: (params) => ({
          url: "/store",
          params: params,
        }),
        providesTags: [STORE_TAG],
      }),
      getStore: build.query({
        query: (id) => `/store/${id}`,
        providesTags: [STORE_TAG],
      }),
      getMyProfile: build.query({
        query: () => "/me",
        providesTags: [STORE_TAG],
      }),
      addStore: build.mutation({
        query: (data) => ({
          url: "/store",
          method: "POST",
          body: data,
        }),
      }),
      updateStore: build.mutation({
        query: (data) => ({
          url: `/store/${data.id}`,
          method: "PATCH",
          body: data,
        }),
      }),
      updateMyProfile: build.mutation({
        query: (data) => ({
          url: `/me`,
          method: "PATCH",
          body: data,
        }),
      }),
      deleteStore: build.query({
        query: (id) => ({
          url: `/store/${id}`,
          method: "DELETE",
        }),
      }),
    }),
    overrideExisting: false,
  });

export const {
  useLazyGetStoresQuery,
  useLazyGetStoreQuery,
  useLazyGetMyProfileQuery,
  useAddStoreMutation,
  useUpdateStoreMutation,
  useUpdateMyProfileMutation,
  useLazyDeleteStoreQuery,
} = StoreApi;
