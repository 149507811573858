import Joi from "joi";

const createCategorySchema = (t) =>
  Joi.object({
    name: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("common:NAME") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("common:NAME") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("common:NAME") })}`,
      }),
    image: Joi.object()
      .when(
        Joi.object({
          image: Joi.valid(null),
        }).unknown(),
        {
          then: Joi.object()
            .required()
            .messages({
              "any.required": `${t("common:IS_REQUIRED", { field: t("common:Image") })}`,
            }),
          otherwise: Joi.object({
            file: Joi.string().allow("").required(),
          }).required(),
        }
      )
      .messages({
        "object.base": `${t("common:IS_REQUIRED", { field: t("common:Image") })}`,
      }),
  });

const updateCategorySchema = (t) =>
  Joi.object({
    id: Joi.number()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("common:ID") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("common:ID") }),
        "number.base": `${t("common:INVALID_VALUE", { field: t("common:ID") })}`,
      }),
    name: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("common:NAME") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("common:NAME") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("common:NAME") })}`,
      }),
  }).unknown(true);

export { createCategorySchema, updateCategorySchema };
