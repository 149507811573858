export const validate = (data, schema, context = {}) => {
  const result = schema.validate(data, { abortEarly: false, context: { ...context } });
  if (!result.error) return null;
  const errors = {};
  result.error.details.forEach((item) => {
    const path = item.path.join(".");
    const { message } = item;
    let obj = errors;
    path.split(".").forEach((key, index, array) => {
      if (!obj[key]) {
        obj[key] = index === array.length - 1 ? message : {};
      }
      obj = obj[key];
    });
  });
  return errors;
};

const validateProperty = ({ name, value }, schema) => {
  const { error } = schema.validate({ [name]: value }, { abortEarly: false });
  return error ? error?.details.find((item) => item?.path[0] === name)?.message : null;
};

export const handleChange = (e, schema, data, errors) => {
  const errorClone = { ...errors };
  const errorMessage = validateProperty(e.target, schema);
  if (errorMessage) errorClone[e.target.name] = errorMessage;
  else errorClone[e.target.name] = "";
  const dataClone = { ...data };
  dataClone[e.target.name] = e.target.value;
  return { dataClone, errorClone };
};
