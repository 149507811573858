import baseApiService from "./base-api.service";

const PRODUCT_TAG = "PRODUCT";

/**
 * Service to fetch data from the API for an endpoint.
 */
const IngredientApi = baseApiService
  .enhanceEndpoints({
    addTagTypes: [PRODUCT_TAG],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getProducts: build.query({
        query: (params) => ({
          url: "/product",
          params: params,
        }),
        providesTags: [PRODUCT_TAG],
      }),
      getProduct: build.query({
        query: (id) => `/product/${id}`,
        providesTags: [PRODUCT_TAG],
      }),
      addProduct: build.mutation({
        query: (formData) => {
          return {
            url: "/product",
            method: "POST",
            body: formData,
          };
        },
      }),
      updateProduct: build.mutation({
        query: (data) => {
          return {
            url: `/product/${data?.id}`,
            method: "PATCH",
            body: data?.submitData,
          };
        },
      }),
      deleteProduct: build.query({
        query: (id) => ({
          url: `/product/${id}`,
          method: "DELETE",
        }),
      }),
    }),
    overrideExisting: false,
  });

export const {
  useLazyGetProductsQuery,
  useLazyGetProductQuery,
  useAddProductMutation,
  useUpdateProductMutation,
  useLazyDeleteProductQuery,
} = IngredientApi;
