import Joi from "joi";
// import { t } from "../../i18n";

const ingredientSchema = (t) =>
  Joi.object({
    name: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("common:NAME") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("common:NAME") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("common:NAME") })}`,
      }),
    price: Joi.number()
      .required()
      .min(0)
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("common:PRICE") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("common:PRICE") }),
        "number.min": `${t("common:INVALID_VALUE", { field: t("common:PRICE") })}`,
        "number.base": `${t("common:INVALID_VALUE", { field: t("common:PRICE") })}`,
      }),
    category: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("ingredients:CATEGORY") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("ingredients:CATEGORY") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("ingredients:CATEGORY") })}`,
      }),
  }).unknown(true);

export default ingredientSchema;
