export default {
  USER_ROLE: {
    SUPER_ADMIN: "admin",
    STORE_ADMIN: "store",
  },
  PRODUCT_CATEGORIES: [
    { label: "Pizza", value: "Pizza" },
    { label: "Wings", value: "Wings" },
    { label: "Pasta", value: "Pasta" },
    { label: "Sides", value: "Sides" },
    { label: "Drinks", value: "Drinks" },
    { label: "Deserts", value: "Deserts" },
    { label: "Dips", value: "Dips" },
  ],
  PRODUCT_CATEGORY: {
    PIZZA: "Pizza",
    WINGS: "Wings",
    PASTA: "Pasta",
    SIDES: "Sides",
    DRINKS: "Drinks",
    DESERTS: "Deserts",
    DIPS: "Dips",
  },
  COUNTRY: [
    { label: "AUS", value: "aus" },
    { label: "NZ", value: "nz" },
  ],
  COUNTRY_OBJ: {
    AUS: "aus",
    NZ: "nz",
  },
  COLORS: [
    { label: "Green", value: "#00FF00" },
    { label: "Red", value: "#FF0000" },
    { label: "Blue", value: "#0000FF" },
    { label: "Purple", value: "#A020F0" },
  ],
  ORDER_STATUS: [
    {
      label: "In-Progress",
      value: "in-progress",
      color: "orange",
    },
    {
      label: "Delivered",
      value: "delivered",
      color: "green",
    },
  ],

  IMAGE_FILE_TYPES: [".jpg", ".jpeg", ".png"],
  ROWS_PER_PAGE_OPTIONS: [5, 10, 25, 50, 100],
  STORE_STATUS: {
    ACTIVE: "active",
    IN_ACTIVE: "inactive",
  },
  INGREDIENTS_CATEGORIES: [
    { label: "Toppings", value: "toppings" },
    { label: "Cheese", value: "cheese" },
    { label: "Sauce", value: "sauce" },
  ],
  INGRIDIENT_STATUS: {
    ENABLED: "enabled",
    DISABLED: "disabled",
  },
  PRODUCT_STATUS: {
    ENABLED: "enabled",
    DISABLED: "disabled",
  },
  CATEGORY_STATUS: {
    ENABLED: "enabled",
    DISABLED: "disabled",
  },
  COUNTRY_FILTER: {
    AUS: "AU",
    NZ: "NZ",
  },
  CRUST_TYPE: {
    deep_pan: "Deep Pan",
    thin: "Thin",
  },
};
