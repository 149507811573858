import Joi from "joi";

const openCloseTimingSchema = (t) =>
  Joi.object({
    day: Joi.string()
      .required()
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("store:DAY") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("store:DAY") }),
      }),
    fromTime: Joi.string()
      .required()
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("store:FROM_TIME") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("store:FROM_TIME") }),
      }),
    toTime: Joi.string()
      .required()
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("store:TO_TIME") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("store:TO_TIME") }),
      }),
  });

const storeSchema = (t) =>
  Joi.object({
    name: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:STORE_NAME") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("store:STORE_NAME") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("store:STORE_NAME") })}`,
      }),
    owner_name: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:OWNER_NAME") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("store:OWNER_NAME") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("store:OWNER_NAME") })}`,
      }),
    email: Joi.string()
      .required()
      .regex(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("common:EMAIL") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("common:EMAIL") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("common:EMAIL") })}`,
        "string.pattern.base": `${t("common:INVALID_VALUE", { field: t("store:EMAIL") })}`,
      }),
    password: Joi.string()
      .when("$isPasswordRequired", {
        is: true,
        then: Joi.required(),
        otherwise: Joi.optional(),
      })
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("common:PASSWORD") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("common:PASSWORD") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("common:PASSWORD") })}`,
      }),
    country: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:COUNTRY") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("store:COUNTRY") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("store:COUNTRY") })}`,
      }),

    phone: Joi.string()
      .required()
      .regex(/^(\+?64|0)[2-9]\d{7,9}$/)
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("common:PHONE") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("common:PHONE") }),
        "string.pattern.base": `${t("common:INVALID_VALUE", { field: t("common:PHONE") })}`,
      }),
    address: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:ADDRESS") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("store:ADDRESS") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("store:ADDRESS") })}`,
      }),
    pincode: Joi.number()
      .required()
      .empty("")
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:PINCODE") })}`,
        "any.empty": t("common:IS_REQUIRED", { field: t("store:PINCODE") }),
        "number.base": `${t("common:INVALID_VALUE", { field: t("store:PINCODE") })}`,
      }),
    city: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:CITY") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("store:CITY") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("store:CITY") })}`,
      }),
    state: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:STATE") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("store:STATE") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("store:STATE") })}`,
      }),
    delivery_time: Joi.number()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:DELIVERY_TIME") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("store:DELIVERY_TIME") }),
        "number.base": `${t("common:INVALID_VALUE", { field: t("store:DELIVERY_TIME") })}`,
      }),
    delivery_charge: Joi.number()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:DELIVERY_CHARGE") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("store:DELIVERY_CHARGE") }),
        "number.base": `${t("common:INVALID_VALUE", { field: t("store:DELIVERY_CHARGE") })}`,
      }),
    pickup_time: Joi.number()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:PICKUP_TIME") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("store:PICKUP_TIME") }),
        "number.base": `${t("common:INVALID_VALUE", { field: t("store:PICKUP_TIME") })}`,
      }),
    tax: Joi.number()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:GST_TAX") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("store:GST_TAX") }),
        "number.base": `${t("common:INVALID_VALUE", { field: t("store:GST_TAX") })}`,
      }),
    delivery_options: Joi.object({
      pickup: Joi.boolean(),
      delivery: Joi.boolean(),
    })
      .or("pickup", "delivery")
      .custom((value, helpers) => {
        if (!value.pickup && !value.delivery) {
          return helpers.error("any.invalid");
        }
        return value;
      })
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:DELIVERY_OPTIONS") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("store:DELIVERY_OPTIONS") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("store:DELIVERY_OPTIONS") })}`,
        "any.invalid": `${t("common:ATLEAST_ONE_REQUIRED", { field: t("store:DELIVERY_OPTION") })}`,
      }),
    payment_options: Joi.object({
      cod: Joi.boolean(),
      card: Joi.boolean(),
    })
      .or("cod", "card")
      .custom((value, helpers) => {
        if (!value.cod && !value.card) {
          return helpers.error("any.invalid");
        }
        return value;
      })
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:PAYMENT_OPTIONS") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("store:PAYMENT_OPTIONS") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("store:PAYMENT_OPTIONS") })}`,
        "any.invalid": `${t("common:ATLEAST_ONE_REQUIRED", { field: t("store:PAYMENT_OPTION") })}`,
      }),
    gluten_free_price: Joi.object({
      large: Joi.number()
        .required()
        .min(0)
        .messages({
          "any.required": t("common:IS_REQUIRED", { field: t("store:LARGE_PIZZA") }),
          "string.empty": t("common:IS_REQUIRED", { field: t("store:LARGE_PIZZA") }),
          "number.min": `${t("common:INVALID_VALUE", { field: t("common:PRICE") })}`,
          "number.base": `${t("common:INVALID_VALUE", { field: t("common:PRICE") })}`,
        }),
      small: Joi.number()
        .required()
        .min(0)
        .messages({
          "any.required": t("common:IS_REQUIRED", { field: t("store:SMALL_PIZZA") }),
          "string.empty": t("common:IS_REQUIRED", { field: t("store:SMALL_PIZZA") }),
          "number.min": `${t("common:INVALID_VALUE", { field: t("common:PRICE") })}`,
          "number.base": `${t("common:INVALID_VALUE", { field: t("common:PRICE") })}`,
        }),
    }),
    open_close_timings: Joi.array()
      .length(7)
      .items(openCloseTimingSchema(t).required())
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("store:OPEN_CLOSE_TIMING") })}`,
        "array.length": `${t("common:INVALID_VALUE", { field: t("store:OPEN_CLOSE_TIMING") })}`,
      }),
    // cardNumber: Joi.string()
    //   .regex(/^(?:4\d{3}|\d{4})\s?\d{4}\s?\d{4}\s?\d{4}$/)
    //   .required()
    //   .messages({
    //     "any.required": `${t("common:IS_REQUIRED", { field: t("store:CARD_NUMBER") })}`,
    //     "string.empty": `${t("common:IS_REQUIRED", { field: t("store:CARD_NUMBER") })}`,
    //     "string.pattern.base": `${t("common:INVALID_VALUE", { field: t("store:CARD_NUMBER") })}`,
    //   }),
    // cardCVV: Joi.string()
    //   .regex(/^[0-9]{3,4}$/)
    //   .required()
    //   .messages({
    //     "any.required": `${t("common:IS_REQUIRED", { field: t("store:CVV") })}`,
    //     "string.empty": `${t("common:IS_REQUIRED", { field: t("store:CVV") })}`,
    //     "string.pattern.base": `${t("common:INVALID_VALUE", { field: t("store:CVV") })}`,
    //   }),
    // cardExpiry: Joi.string()
    //   .regex(/^(0[1-9]|1[0-2])\/[0-9]{2}$/)
    //   .required()
    //   .messages({
    //     "any.required": `${t("common:IS_REQUIRED", { field: t("store:EXPIRY") })}`,
    //     "string.empty": `${t("common:IS_REQUIRED", { field: t("store:EXPIRY") })}`,
    //     "string.pattern.base": `${t("common:INVALID_VALUE", { field: t("store:EXPIRY") })}`,
    //   }),
    // cardHolderName: Joi.string()
    //   .required()
    //   .messages({
    //     "any.required": `${t("common:IS_REQUIRED", { field: t("store:CARDHOLDER_NAME") })}`,
    //     "string.empty": t("common:IS_REQUIRED", { field: t("store:CARDHOLDER_NAME") }),
    //     "string.base": `${t("common:INVALID_VALUE", { field: t("store:CARDHOLDER_NAME") })}`,
    //   }),
  }).unknown(true);

export default storeSchema;
