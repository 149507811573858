import { Card, Container, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { isSuperAdmin } from "lib/helperFuctions";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import MDTypography from "components/MDTypography";
import { handleChange, validate } from "lib/joiHelper";
import BasicDetail from "./components/BasicDetail";
import TimingDetail from "./components/TimingDetail";
import "./profile.css";
import storeSchema from "./schema";
// import CardDetail from "./components/CardDetail";
import FormButtons from "../../components/FormButtons";
import Loader from "../../components/Loader";
import {
  useAddStoreMutation,
  useLazyGetMyProfileQuery,
  useLazyGetStoreQuery,
  useUpdateMyProfileMutation,
  useUpdateStoreMutation,
} from "../../services/store.service";

const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function Profile() {
  const intialValues = {
    name: "",
    owner_name: "",
    email: "",
    password: "",
    address: "",
    phone: "",
    pincode: "",
    city: "",
    state: "",
    country: "",
    delivery_time: "",
    delivery_charge: "",
    pickup_time: "",
    tax: "",
    delivery_options: {
      pickup: false,
      delivery: false,
    },
    payment_options: {
      cod: false,
      card: false,
    },
    gluten_free_price: {
      large: "",
      small: "",
    },
    open_close_timings: daysOfWeek.map((day) => ({
      day: day,
      fromTime: "11:00",
      toTime: "19:00",
    })),
    // cardNumber: "",
    // cardHolderName: "",
    // cardExpiry: "",
    // cardCVV: "",
  };

  const initialError = {
    ...intialValues,
    delivery_options: "",
    payment_options: "",
    open_close_timings: null,
  };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const [data, setData] = useState(intialValues);
  const [error, setError] = useState(initialError);
  const [loading, setLoading] = useState(false);

  const [addStoreTrigger] = useAddStoreMutation();
  const [updateStoreTrigger] = useUpdateStoreMutation();
  const [updateMyProfileTrigger] = useUpdateMyProfileMutation();
  const [getStoreTrigger] = useLazyGetStoreQuery();
  const [getMyProfileTrigger] = useLazyGetMyProfileQuery();

  const fetchStore = useCallback(async (id) => {
    setLoading(true);
    const {
      data: apiData,
      isSuccess,
      isError,
      // error: apiError,
    } = await getStoreTrigger(id);
    if (isSuccess) {
      setData({ ...apiData?.data });
    }
    setLoading(false);
    if (isError) {
      navigate(isSuperAdmin() ? "/stores" : "/products");
    }
  });

  const fetchMyProfile = useCallback(async () => {
    setLoading(true);
    const {
      data: apiData,
      isSuccess,
      isError,
      // error: apiError,
    } = await getMyProfileTrigger();
    if (isSuccess) {
      setData({ ...apiData?.data });
    }
    setLoading(false);
    if (isError) {
      navigate(isSuperAdmin() ? "/stores" : "/products");
    }
  });

  useEffect(() => {
    async function validateParams() {
      if (params?.storeId) {
        fetchStore(params?.storeId);
      }
      if (location.pathname === "/profile") {
        fetchMyProfile();
      }
    }
    validateParams();
  }, []);

  const schema = storeSchema(t);

  const onChangeHandler = (e) => {
    const dataClone = { ...data };
    setData({ ...dataClone, [e.target.name]: e?.target?.value });
  };

  const onBlurHandler = (e) => {
    const { dataClone, errorClone } = handleChange(e, schema, data, error);
    setData(dataClone);
    setError(errorClone);
  };

  const onSubmitHandler = async () => {
    const errorClone = validate(data, schema, {
      isPasswordRequired: Boolean(!data?.id),
    });
    if (errorClone) {
      setError(errorClone);
    } else {
      setError({ ...initialError });
      let result;
      if (!params?.storeId && isSuperAdmin()) {
        result = await addStoreTrigger({ ...data, status: "active" });
      } else if (isSuperAdmin()) {
        result = await updateStoreTrigger({ ...data });
      } else {
        result = await updateMyProfileTrigger({ ...data });
      }
      if ("error" in result) {
        toast.error(result?.error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
      } else {
        toast.success(result?.data?.msg);
        if (isSuperAdmin()) {
          navigate("/stores");
        }
      }
    }
  };

  const handleTimeChange = (day, value, label) => {
    const dataClone = { ...data };
    dataClone.open_close_timings = dataClone.open_close_timings.map((row) =>
      row.day === day ? { ...row, [label]: value } : row
    );
    setData(dataClone);
  };

  const handleGlutenChange = (e) => {
    const dataClone = { ...data };
    dataClone.gluten_free_price = {
      ...dataClone.gluten_free_price,
      [e.target.name]: e.target.value,
    };
    setData(dataClone);
  };

  const handleCheckboxChange = (event, field) => {
    const { name, checked } = event.target;
    const dataClone = { ...data };
    dataClone[field] = { ...dataClone[field], [name]: checked };
    setData(dataClone);
  };

  const isViewOnlyPage = () => {
    return location?.pathname?.includes("view");
  };

  const isUpdatePage = () => {
    return location?.pathname?.includes("edit") || location?.pathname?.includes("profile");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  {location?.state?.pageTitle || t("store:STORE_PROFILE")}
                </MDTypography>
              </MDBox>
              <Container maxWidth="lg">
                <BasicDetail
                  data={data}
                  error={error}
                  onBlurHandler={onBlurHandler}
                  handleCheckboxChange={handleCheckboxChange}
                  onChangeHandler={onChangeHandler}
                  onGlutenChange={handleGlutenChange}
                  isViewOnly={isViewOnlyPage()}
                />
                {/* <GlutenFreeDetail
                  data={data}
                  error={error}
                  handleCheckboxChange={handleCheckboxChange}
                  onChangeHandler={handleGlutenChange}
                  isViewOnly={isViewOnlyPage()}
                /> */}
                <TimingDetail
                  data={data}
                  error={error}
                  handleTimeChange={handleTimeChange}
                  isViewOnly={isViewOnlyPage()}
                />
                {/* <CardDetail data={data} error={error} onBlurHandler={onBlurHandler} /> */}

                {!isViewOnlyPage() && (
                  <FormButtons
                    cancelLabel={t("common:CANCEL")}
                    submitLabel={isUpdatePage() ? t("common:UPDATE") : t("common:SAVE")}
                    submitHandler={onSubmitHandler}
                    cancelHandler={() => navigate("/stores")}
                  />
                )}
              </Container>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Loader open={loading} />
    </DashboardLayout>
  );
}

export default Profile;
