import { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// React components

// React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// React themes
import theme from "assets/theme";

// React Dark Mode themes
import themeDark from "assets/theme-dark";

// React routes
import routes from "routes";

// React contexts
import {
  useMaterialUIController,
  setMiniSidenav,
  // setOpenConfigurator
} from "context";

// Custom
import Protected from "ProtectedRoute";
import SignIn from "layouts/sign-in";

// Helpers
import { isSuperAdmin } from "lib/helperFuctions";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "redux/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { CurrentUrlProvider } from "./CurrentUrlContext";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    // openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const auth = localStorage.getItem("authToken") !== null;
  const defaultRedirect = isSuperAdmin() ? "/stores" : "/products";

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) => {
    const validRoutes = isSuperAdmin()
      ? allRoutes
      : allRoutes?.filter((route) => route.isStoreMenu);

    return validRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            path={route.route}
            element={<Protected isSignedIn={auth}>{route.component}</Protected>}
            key={route.key}
          />
        );
      }

      return null;
    });
  };
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CurrentUrlProvider>
          <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {layout === "dashboard" && auth && (
              <>
                <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  brandName="Soul Pizza"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
                <Configurator />
              </>
            )}
            <Routes>
              <Route path="/sign-in" element={<SignIn />} />
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to={auth ? defaultRedirect : "sign-in"} />} />
            </Routes>
          </ThemeProvider>
          <ToastContainer />
        </CurrentUrlProvider>
      </PersistGate>
    </Provider>
  );
}
