import React, { useCallback, useEffect, useState } from "react";
import { Card, Grid, Icon, IconButton, Switch } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import { toast } from "react-toastify";

import DataTable from "../../examples/Tables/DataTable";
import AddCategoryDialog from "./AddCategory";
import Loader from "../../components/Loader";
import {
  useAddCategoryMutation,
  useLazyGetCategoriesQuery,
  useUpdateCategoryMutation,
} from "../../services/product-category.service";
import Constants from "../../lib/Constants";
import { isSuperAdmin } from "../../lib/helperFuctions";

function ProductsCategories() {
  const initialState = {
    name: "",
    image: null,
  };
  const [formData, setFormData] = useState({ ...initialState });
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  const [categories, setCategories] = useState({
    total: 0,
    data: [],
  });
  const [loading, setLoading] = useState(false);

  const [getCategories] = useLazyGetCategoriesQuery();
  const [addCategoryTrigger] = useAddCategoryMutation();
  const [updateCategoryTrigger] = useUpdateCategoryMutation();

  const fetchCategories = useCallback(async () => {
    setLoading(true);
    const { data, isSuccess, isError, error } = await getCategories();

    if (isSuccess) {
      setCategories(data);
    }

    if (isError) {
      console.log(error);
    }
    setLoading(false);
  });

  useEffect(() => {
    fetchCategories();
  }, []);

  const onEditHandler = (category) => {
    setFormData({
      id: category?.id,
      name: category?.name,
      image: null,
    });
    setDialogOpen(true);
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setFormData({ ...initialState });
    setDialogOpen(false);
  };

  const handleEnableDisable = async (categoryId, categoryStatus) => {
    const updatedStatus =
      categoryStatus === Constants.CATEGORY_STATUS.ENABLED
        ? Constants.CATEGORY_STATUS.DISABLED
        : Constants.CATEGORY_STATUS.ENABLED;

    const categoriesClone = { ...categories };

    categoriesClone.data = categoriesClone?.data?.map((ingredient) => {
      if (ingredient?.id === categoryId) {
        ingredient = { ...ingredient, status: updatedStatus };
      }
      return ingredient;
    });
    setCategories(categoriesClone);
    const submitData = new FormData();
    submitData.append("status", updatedStatus);
    const result = await updateCategoryTrigger({
      submitData: isSuperAdmin() ? submitData : { status: updatedStatus },
      id: categoryId,
    });
    if ("error" in result) {
      toast.error(result?.error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
    } else {
      toast.success(result?.data?.msg);
    }
  };

  const handleSubmitForm = async (submitData) => {
    let result;
    if (formData.id) {
      result = await updateCategoryTrigger({ submitData, id: formData?.id });
    } else {
      console.log(submitData.get("image"));
      result = await addCategoryTrigger(submitData);
    }

    if ("error" in result) {
      toast.error(result?.error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
    } else {
      toast.success(result?.data?.msg);
      await fetchCategories();
    }
    handleClose();
  };

  // const onDeleteHandler = async (item) => {
  //   console.log(item);
  // };

  const categoryColumns = () => {
    const columns = [
      { Header: t("common:IMAGE"), accessor: "image", width: "30%" },
      { Header: t("common:NAME"), accessor: "name", width: "30%" },
      { Header: t("common:ENABLE"), accessor: "enable", align: "center" },
    ];

    if (isSuperAdmin()) {
      columns.push({ Header: t("common:ACTION"), accessor: "action", align: "center" });
    }

    return columns;
  };

  const rows = categories?.data?.map((item) => {
    const rowObj = {
      image: <img src={item.image} height="60" width="60" alt={item.name} />,
      name: (
        <MDTypography variant="caption" color="text" fontWeight="medium">
          {item.name}
        </MDTypography>
      ),
      enable: (
        <Switch
          inputProps={{ "aria-label": "Enable Category" }}
          value="1"
          color="warning"
          checked={item?.status === Constants.CATEGORY_STATUS.ENABLED}
          onChange={() => handleEnableDisable(item?.id, item?.status)}
        />
      ),
    };

    if (isSuperAdmin()) {
      rowObj.action = (
        <MDTypography variant="caption" fontWeight="medium">
          <IconButton aria-label="edit" color="secondary" onClick={() => onEditHandler(item)}>
            <EditIcon />
          </IconButton>
          {/* <IconButton aria-label="delete" color="error" onClick={() => onDeleteHandler(item)}>
                <DeleteIcon />
              </IconButton> */}
        </MDTypography>
      );
    }

    return rowObj;
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddCategoryDialog
        dialogOpen={dialogOpen}
        handleClose={handleClose}
        handleSubmitForm={handleSubmitForm}
        data={formData}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  {t("categories:PRODUCT_CATEGORIES")}
                </MDTypography>
                {isSuperAdmin() && (
                  <MDButton onClick={handleClickOpen} color="white" size="small">
                    <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                    &nbsp;{t("categories:ADD_NEW_CATEGORY")}
                  </MDButton>
                )}
              </MDBox>
              <MDBox pt={3}>
                <DataTable table={{ columns: categoryColumns(), rows }} isSorted noEndBorder />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Loader open={loading} />
    </DashboardLayout>
  );
}

export default ProductsCategories;
