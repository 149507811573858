import Joi from "joi";

const createPizzaSchema = (t) =>
  Joi.object({
    name: Joi.string()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("common:NAME") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("common:NAME") }),
        "string.base": t("common:INVALID_VALUE", { field: t("common:NAME") }),
      }),
    label_color: Joi.string()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("product:PRODUCT_LABEL_COLOR") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("product:PRODUCT_LABEL_COLOR") }),
        "string.base": t("common:INVALID_VALUE", { field: t("product:PRODUCT_LABEL_COLOR") }),
      }),
    productCategory: Joi.number()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("product:PRODUCT_CATEGORY") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("product:PRODUCT_CATEGORY") }),
        "number.base": t("common:INVALID_VALUE", { field: t("product:PRODUCT_CATEGORY") }),
      }),
    optIngredients: Joi.array(),
    defaultIngredients: Joi.array()
      .required()
      .min(1)
      .items(
        Joi.number()
          .required()
          .messages({
            "any.required": t("common:IS_REQUIRED"),
            "string.empty": t("common:IS_REQUIRED"),
            "number.base": t("common:INVALID_VALUE"),
          })
      )
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("product:DEFAULT_INGREDIENTS") }),
        "array.base": "The input must be an array",
        "array.min": "The input must contain at least 1 item",
      }),
    sub_category: Joi.string()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("product:SUB_CATEGORY") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("product:SUB_CATEGORY") }),
        "string.base": t("common:INVALID_VALUE", { field: t("product:SUB_CATEGORY") }),
      }),
    price: Joi.array()
      .required()
      .min(1)
      .items(
        Joi.object({
          country: Joi.string()
            .required()
            .min(1)
            .messages({
              "any.required": t("common:IS_REQUIRED", { field: t("common:COUNTRY") }),
              "string.empty": t("common:IS_REQUIRED", { field: t("common:COUNTRY") }),
              "string.base": t("common:INVALID_VALUE", { field: t("common:COUNTRY") }),
            }),
          price: Joi.object({
            large: Joi.number()
              .required()
              .min(1)
              .messages({
                "any.required": t("common:IS_REQUIRED", { field: t("product:LARGE") }),
                "number.base": t("common:INVALID_VALUE", { field: t("product:LARGE") }),
                "number.min": t("common:INVALID_VALUE", { field: t("product:LARGE") }),
              }),
            small: Joi.number()
              .required()
              .min(1)
              .messages({
                "any.required": t("common:IS_REQUIRED", { field: t("product:SMALL") }),
                "number.base": t("common:INVALID_VALUE", { field: t("product:SMALL") }),
                "number.min": t("common:INVALID_VALUE", { field: t("product:SMALL") }),
              }),
          }).messages({
            "any.required": t("common:IS_REQUIRED", { field: t("common:PRICE") }),
          }),
        })
      ),
    image: Joi.object()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("common:Image") })}`,
        "object.base": `${t("common:IS_REQUIRED", { field: t("common:Image") })}`,
      })
      .messages({
        "object.base": `${t("common:IS_REQUIRED", { field: t("common:Image") })}`,
      }),
  });

const updatePizzaSchema = (t) =>
  Joi.object({
    name: Joi.string()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("common:NAME") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("common:NAME") }),
        "string.base": t("common:INVALID_VALUE", { field: t("common:NAME") }),
      }),
    label_color: Joi.string()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("product:PRODUCT_LABEL_COLOR") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("product:PRODUCT_LABEL_COLOR") }),
        "string.base": t("common:INVALID_VALUE", { field: t("product:PRODUCT_LABEL_COLOR") }),
      }),
    productCategory: Joi.number()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("product:PRODUCT_CATEGORY") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("product:PRODUCT_CATEGORY") }),
        "number.base": t("common:INVALID_VALUE", { field: t("product:PRODUCT_CATEGORY") }),
      }),
    optIngredients: Joi.array(),
    defaultIngredients: Joi.array()
      .required()
      .min(1)
      .items(
        Joi.number()
          .required()
          .messages({
            "any.required": t("common:IS_REQUIRED"),
            "string.empty": t("common:IS_REQUIRED"),
            "number.base": t("common:INVALID_VALUE"),
          })
      )
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("product:DEFAULT_INGREDIENTS") }),
        "array.base": "The input must be an array",
        "array.min": "The input must contain at least 1 item",
      }),
    sub_category: Joi.string()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("product:SUB_CATEGORY") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("product:SUB_CATEGORY") }),
        "string.base": t("common:INVALID_VALUE", { field: t("product:SUB_CATEGORY") }),
      }),
    price: Joi.array()
      .required()
      .min(1)
      .items(
        Joi.object({
          country: Joi.string()
            .required()
            .min(1)
            .messages({
              "any.required": t("common:IS_REQUIRED", { field: t("common:COUNTRY") }),
              "string.empty": t("common:IS_REQUIRED", { field: t("common:COUNTRY") }),
              "string.base": t("common:INVALID_VALUE", { field: t("common:COUNTRY") }),
            }),
          price: Joi.object({
            large: Joi.number()
              .required()
              .min(1)
              .messages({
                "any.required": t("common:IS_REQUIRED", { field: t("product:LARGE") }),
                "number.base": t("common:INVALID_VALUE", { field: t("product:LARGE") }),
                "number.min": t("common:INVALID_VALUE", { field: t("product:LARGE") }),
              }),
            small: Joi.number()
              .required()
              .min(1)
              .messages({
                "any.required": t("common:IS_REQUIRED", { field: t("product:SMALL") }),
                "number.base": t("common:INVALID_VALUE", { field: t("product:SMALL") }),
                "number.min": t("common:INVALID_VALUE", { field: t("product:SMALL") }),
              }),
          }).messages({
            "any.required": t("common:IS_REQUIRED", { field: t("common:PRICE") }),
          }),
        })
      ),
    id: Joi.required(),
  }).unknown(true);

const createNonPizzaSchema = (t) =>
  Joi.object({
    name: Joi.string()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("common:NAME") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("common:NAME") }),
        "string.base": t("common:INVALID_VALUE", { field: t("common:NAME") }),
      }),
    label_color: Joi.string()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("product:PRODUCT_LABEL_COLOR") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("product:PRODUCT_LABEL_COLOR") }),
        "string.base": t("common:INVALID_VALUE", { field: t("product:PRODUCT_LABEL_COLOR") }),
      }),
    productCategory: Joi.number()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("product:PRODUCT_CATEGORY") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("product:PRODUCT_CATEGORY") }),
        "number.base": t("common:INVALID_VALUE", { field: t("product:PRODUCT_CATEGORY") }),
      }),
    price: Joi.array()
      .required()
      .min(1)
      .items(
        Joi.object({
          country: Joi.string()
            .required()
            .min(1)
            .messages({
              "any.required": t("common:IS_REQUIRED", { field: t("common:COUNTRY") }),
              "string.empty": t("common:IS_REQUIRED", { field: t("common:COUNTRY") }),
              "string.base": t("common:INVALID_VALUE", { field: t("common:COUNTRY") }),
            }),
          price: Joi.number()
            .required()
            .min(1)
            .messages({
              "any.required": t("common:IS_REQUIRED", { field: t("common:PRICE") }),
              "number.base": t("common:INVALID_VALUE", { field: t("common:PRICE") }),
              "number.min": t("common:INVALID_VALUE", { field: t("common:PRICE") }),
            }),
        })
      ),
    image: Joi.object()
      .required()
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("common:IMAGE") }),
        "object.base": t("common:IS_REQUIRED", { field: t("common:IMAGE") }),
      }),
  }).messages({
    "object.unknown": t("common:UNKNOWN_FIELD"),
  });

const updateNonPizzaSchema = (t) =>
  Joi.object({
    name: Joi.string()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("common:NAME") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("common:NAME") }),
        "string.base": t("common:INVALID_VALUE", { field: t("common:NAME") }),
      }),
    label_color: Joi.string()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("product:PRODUCT_LABEL_COLOR") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("product:PRODUCT_LABEL_COLOR") }),
        "string.base": t("common:INVALID_VALUE", { field: t("product:PRODUCT_LABEL_COLOR") }),
      }),
    productCategory: Joi.number()
      .required()
      .min(1)
      .messages({
        "any.required": t("common:IS_REQUIRED", { field: t("product:PRODUCT_CATEGORY") }),
        "string.empty": t("common:IS_REQUIRED", { field: t("product:PRODUCT_CATEGORY") }),
        "number.base": t("common:INVALID_VALUE", { field: t("product:PRODUCT_CATEGORY") }),
      }),
    price: Joi.array()
      .required()
      .min(1)
      .items(
        Joi.object({
          country: Joi.string()
            .required()
            .min(1)
            .messages({
              "any.required": t("common:IS_REQUIRED", { field: t("common:COUNTRY") }),
              "string.empty": t("common:IS_REQUIRED", { field: t("common:COUNTRY") }),
              "string.base": t("common:INVALID_VALUE", { field: t("common:COUNTRY") }),
            }),
          price: Joi.number()
            .required()
            .min(1)
            .messages({
              "any.required": t("common:IS_REQUIRED", { field: t("common:PRICE") }),
              "number.base": t("common:INVALID_VALUE", { field: t("common:PRICE") }),
              "number.min": t("common:INVALID_VALUE", { field: t("common:PRICE") }),
            }),
        })
      ),
    id: Joi.required(),
  })
    .messages({
      "object.unknown": t("common:UNKNOWN_FIELD"),
    })
    .unknown(true);

export { createPizzaSchema, updatePizzaSchema, createNonPizzaSchema, updateNonPizzaSchema };
