import baseApiService from "./base-api.service";

const PRODUCT_CATEGORY_TAG = "PRODUCT_CATEGORY";

/**
 * Service to fetch data from the API for an endpoint.
 */
const IngredientApi = baseApiService
  .enhanceEndpoints({
    addTagTypes: [PRODUCT_CATEGORY_TAG],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getCategories: build.query({
        query: () => ({
          url: "/productCategory",
        }),
        providesTags: [PRODUCT_CATEGORY_TAG],
      }),
      addCategory: build.mutation({
        query: (formData) => {
          return {
            url: "/productCategory",
            method: "POST",
            body: formData,
          };
        },
      }),
      updateCategory: build.mutation({
        query: (data) => {
          return {
            url: `/productCategory/${data?.id}`,
            method: "PATCH",
            body: data?.submitData,
          };
        },
      }),
      deleteCategory: build.query({
        query: (id) => ({
          url: `/productCategory/${id}`,
          method: "DELETE",
        }),
      }),
    }),
    overrideExisting: false,
  });

export const {
  useLazyGetCategoriesQuery,
  useAddCategoryMutation,
  useUpdateCategoryMutation,
  useLazyDeleteCategoryQuery,
} = IngredientApi;
