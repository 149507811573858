import Joi from "joi";

const emailSignInSchema = (t) =>
  Joi.object({
    email: Joi.string()
      .required()
      .regex(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("common:EMAIL") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("common:EMAIL") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("common:EMAIL") })}`,
        "string.pattern.base": `${t("common:INVALID_VALUE", { field: t("common:EMAIL") })}`,
      }),
    password: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("signIn:PASSWORD") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("signIn:PASSWORD") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("signIn:PASSWORD") })}`,
      }),
  });

const usernameSignInSchema = (t) =>
  Joi.object({
    username: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("signIn:USER_NAME") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("signIn:USER_NAME") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("signIn:USER_NAME") })}`,
      }),
    password: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("signIn:PASSWORD") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("signIn:PASSWORD") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("signIn:PASSWORD") })}`,
      }),
  });

export { emailSignInSchema, usernameSignInSchema };
