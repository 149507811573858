import React from "react";
import { Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import FormSeparator from "assets/theme/components/FormSeparator";
import MDBox from "components/MDBox";

const TimePicker = ({ label, value, onChange, error, disabled }) => {
  return (
    <TextField
      label={label}
      fullWidth
      type="time"
      value={value}
      error={error?.length > 0}
      helperText={error}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
    />
  );
};

const TimingDetail = ({ data, error, handleTimeChange, isViewOnly }) => {
  const { t } = useTranslation();

  const getHoursError = (index, component) => {
    if (!error || typeof error !== "object" || !error.open_close_timings) {
      return "";
    }

    const timing = error.open_close_timings[index];
    if (!timing || typeof timing !== "object" || !timing[component]) {
      return "";
    }

    return timing[component];
  };

  return (
    <MDBox pt={3} pb={1}>
      <FormSeparator label={t("store:OPENING_CLOSING_HOURS_DETAILS")} />
      <Grid container spacing={2} pt={2}>
        {data?.open_close_timings?.map((row, index) => (
          <React.Fragment key={row.day}>
            <Grid item xs={12} sm={2}>
              <span className="week-day-label">{row.day} :</span>
            </Grid>
            <Grid item xs={6} sm={5}>
              <TimePicker
                label={t("store:FROM")}
                value={row.fromTime}
                error={getHoursError(index, "fromTime")}
                onChange={(value) => handleTimeChange(row.day, value, "fromTime")}
                disabled={isViewOnly}
              />
            </Grid>
            <Grid item xs={6} sm={5}>
              <TimePicker
                label={t("store:TO")}
                value={row.toTime}
                error={getHoursError(index, "toTime")}
                onChange={(value) => handleTimeChange(row.day, value, "toTime")}
                disabled={isViewOnly}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </MDBox>
  );
};

export default TimingDetail;
