import React, { createContext, useContext, useState } from "react";
import { isSuperAdmin } from "./lib/helperFuctions";

const CurrentUrlContext = createContext();

const CurrentUrlProvider = ({ children }) => {
  const [currentUrl, setCurrentUrl] = useState({
    url: window.location.href,
    isSuperAdmin: isSuperAdmin(),
  });

  const handleUrlChange = () => {
    setCurrentUrl({
      url: window.location.href,
      isSuperAdmin: isSuperAdmin(),
    });
  };

  // add a listener to handle URL changes
  window.addEventListener("popstate", handleUrlChange);

  return <CurrentUrlContext.Provider value={currentUrl}>{children}</CurrentUrlContext.Provider>;
};

const useCurrentUrl = () => useContext(CurrentUrlContext);

export { CurrentUrlProvider, useCurrentUrl };
