import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { Card, Grid, Icon, Switch, TablePagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import TableCellWithSubtitle from "components/TableCellWithSubtitle";
import DataTable from "../../examples/Tables/DataTable";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import TableActions from "../../components/TableActions";
import {
  useLazyGetStoresQuery,
  useLazyDeleteStoreQuery,
  useUpdateStoreMutation,
} from "../../services/store.service";
import Loader from "../../components/Loader";
import Constants from "../../lib/Constants";

function Stores() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [stores, setStores] = useState({
    total: 0,
    data: [],
  });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [deleteStoreId, setDeleteStoreId] = useState("");

  const columns = [
    { Header: t("store:STORE"), accessor: "store", align: "left" },
    { Header: t("store:OWNER"), accessor: "owner", align: "left" },
    { Header: t("common:PHONE"), accessor: "phone", align: "left" },
    { Header: t("common:CREATED_AT"), accessor: "createdAt", align: "left" },
    { Header: "enable", accessor: "enable", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const [getStores] = useLazyGetStoresQuery();
  const [deleteStore] = useLazyDeleteStoreQuery();
  const [updateStoreTrigger] = useUpdateStoreMutation();

  const fetchStores = useCallback(async () => {
    setLoading(true);
    const { data, isSuccess, isError, error } = await getStores({
      search,
      page: page + 1,
      limit: rowsPerPage,
    });
    if (isSuccess) {
      setStores(data);
    }
    setLoading(false);
    if (isError) {
      console.log(error);
    }
  });

  useEffect(() => {
    fetchStores();
  }, []);

  useEffect(() => {
    fetchStores();
  }, [search, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));

    setPage(0);
  };

  const addStoreHandler = () => {
    navigate("add", { state: { pageTitle: t("store:ADD_NEW_STORE") } });
  };

  const editStoreHandler = (id) => {
    navigate(`edit/${id}`, { state: { pageTitle: t("store:EDIT_STORE") } });
  };

  const viewStoreHandler = (id) => {
    navigate(`view/${id}`, { state: { pageTitle: t("store:VIEW_STORE") } });
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = (id) => {
    setDeleteStoreId(id);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = async () => {
    setDialogOpen(false);
    setLoading(true);
    const { data, isSuccess, isError, error } = await deleteStore(deleteStoreId);
    if (isSuccess) {
      toast.success(data?.msg);
      fetchStores();
    }
    setLoading(false);
    if (isError) {
      toast.error(error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
    }
  };

  const handleEnableDisable = async (storeId, storeStatus) => {
    const updatedStatus =
      storeStatus === Constants.STORE_STATUS.ACTIVE
        ? Constants.STORE_STATUS.IN_ACTIVE
        : Constants.STORE_STATUS.ACTIVE;

    const storesClone = { ...stores };

    storesClone.data = storesClone?.data?.map((store) => {
      if (store?.id === storeId) {
        store = { ...store, status: updatedStatus };
      }
      return store;
    });
    setStores(storesClone);

    const result = await updateStoreTrigger({
      status: updatedStatus,
      id: storeId,
    });
    if ("error" in result) {
      toast.error(result?.error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
    } else {
      toast.success(result?.data?.msg);
    }
  };

  const rows = stores?.data?.map((store) => ({
    store: <TableCellWithSubtitle title={store.name} subtitle={store.pincode} />,
    owner: <TableCellWithSubtitle title={store.owner_name} subtitle={store.email} />,
    phone: (
      <MDTypography variant="caption" fontWeight="medium">
        {store.phone}
      </MDTypography>
    ),
    createdAt: (
      <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
        {moment(store.createdAt).format("MMM DD, YYYY")}
      </MDTypography>
    ),
    enable: (
      <Switch
        checked={store?.status === Constants.STORE_STATUS.ACTIVE}
        color="success"
        value={store?.status}
        onChange={() => handleEnableDisable(store?.id, store?.status)}
      />
    ),
    action: (
      <TableActions
        record={store}
        onEdit={editStoreHandler}
        onView={viewStoreHandler}
        onDelete={handleDialogOpen}
      />
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ConfirmationDialog
        title={t("store:DELETE_STORE")}
        content={t("common:ARE_YOU_SURE_YOU_WANT_TO_DELETE")}
        confirmText={t("common:CONFIRM")}
        cancelText={t("common:CANCEL")}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleConfirm}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  {t("store:STORES")}
                </MDTypography>
                <MDButton onClick={addStoreHandler} color="white" size="small">
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;{t("store:ADD_NEW_STORE")}
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage
                  showTotalEntries
                  canSearch
                  onSearchHandler={(value) => setSearch(value)}
                  noEndBorder
                />
                <TablePagination
                  rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPTIONS}
                  component="div"
                  count={stores?.total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Loader open={loading} />
    </DashboardLayout>
  );
}

export default Stores;
