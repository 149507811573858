import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MDButton from "components/MDButton";

const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  confirmText,
  cancelText,
}) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <MDButton onClick={onClose} color="secondary" variant="text">
          {cancelText}
        </MDButton>
        <MDButton onClick={onConfirm} color="info" variant="gradient">
          {confirmText}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
