import Joi from "joi";

const changePasswordSchema = (t) =>
  Joi.object({
    currentPassword: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("signIn:CURRENT_PASSWORD") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("signIn:CURRENT_PASSWORD") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("signIn:CURRENT_PASSWORD") })}`,
      }),
    newPassword: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("signIn:NEW_PASSWORD") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("signIn:NEW_PASSWORD") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("signIn:NEW_PASSWORD") })}`,
      }),
    confirmPassword: Joi.string()
      .required()
      .messages({
        "any.required": `${t("common:IS_REQUIRED", { field: t("signIn:CONFIRM_PASSWORD") })}`,
        "string.empty": t("common:IS_REQUIRED", { field: t("signIn:CONFIRM_PASSWORD") }),
        "string.base": `${t("common:INVALID_VALUE", { field: t("signIn:CONFIRM_PASSWORD") })}`,
      }),
  });

export default changePasswordSchema;
