import React, { useState } from "react";
import moment from "moment/moment";
import { Card, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TableCellWithSubtitle from "components/TableCellWithSubtitle";
import dummyData from "lib/dummyData";
import DataTable from "../../examples/Tables/DataTable";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import TableActions from "../../components/TableActions";

function TransactionsList() {
  const { t } = useTranslation();

  const columns = [
    { Header: t("transaction:ORDER_NUMBER"), accessor: "store", align: "left" },
    { Header: t("common:NAME"), accessor: "owner", align: "left" },
    { Header: t("common:AMOUNT"), accessor: "amount", align: "left" },
    { Header: t("transaction:ORDER_ITEMS"), accessor: "items", align: "left" },
    { Header: t("transaction:STATUS"), accessor: "status", align: "left" },
    { Header: t("transaction:UPDATE_ON"), accessor: "createdAt", align: "left" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const editTransactionHandler = (id) => {
    console.log(id);
  };

  const viewTransactionHandler = (id) => {
    console.log(id);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    setDialogOpen(false);
  };

  const rows = dummyData.trnasactions?.map((transaction) => ({
    store: (
      <TableCellWithSubtitle
        title={transaction.transactionId}
        subtitle={transaction.transactionId}
      />
    ),
    owner: <TableCellWithSubtitle title={transaction.customerName} />,
    amount: <TableCellWithSubtitle title={transaction.amount} />,
    items: <TableCellWithSubtitle title={transaction.orderItems.length} />,
    status: (
      <MDTypography variant="caption" fontWeight="medium">
        {transaction.status}
      </MDTypography>
    ),
    createdAt: (
      <MDTypography component="a" href="#" variant="caption" fontWeight="medium">
        {moment(transaction.createdAt).format("MMM DD, YYYY")}
      </MDTypography>
    ),
    action: (
      <TableActions
        record={transaction}
        onEdit={editTransactionHandler}
        onView={viewTransactionHandler}
        onDelete={handleDialogOpen}
      />
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ConfirmationDialog
        title={t("transaction:DELETE_TRANSACTION")}
        content={t("common:ARE_YOU_SURE_YOU_WANT_TO_DELETE")}
        confirmText={t("common:CONFIRM")}
        cancelText={t("common:CANCEL")}
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleConfirm}
      />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  {t("transaction:TRANSACTION_LIST_TITLE")}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  entriesPerPage
                  showTotalEntries
                  canSearch
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TransactionsList;
