import baseApiService from "./base-api.service";

const SIGN_IN_TAG = "SIGNIN";

/**
 * Service to fetch data from the API for an endpoint.
 */
const SignInApi = baseApiService
  .enhanceEndpoints({
    addTagTypes: [SIGN_IN_TAG],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      login: build.mutation({
        query: (data) => ({
          url: "/login",
          method: "POST",
          body: data,
        }),
      }),
    }),
    overrideExisting: false,
  });

export const { useLoginMutation } = SignInApi;
