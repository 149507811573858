import { useTranslation } from "react-i18next";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import FormSeparator from "assets/theme/components/FormSeparator";
import MDBox from "components/MDBox";
import CountryDropDown from "components/CountryDropDown";
import { useLazyGetStatesQuery, useLazyGetCitiesQuery } from "services/states-cities.service";
import { useEffect, useState } from "react";
import DropDown from "components/DropDown";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Constants from "../../../lib/Constants";

const BasicDetail = ({
  data,
  error,
  onBlurHandler,
  handleCheckboxChange,
  onChangeHandler,
  onGlutenChange,
  isViewOnly,
}) => {
  const { t } = useTranslation();

  const [stateArray, setStateArray] = useState();
  const [cityArray, setCityArray] = useState();

  const [getStates] = useLazyGetStatesQuery();
  const [getCities] = useLazyGetCitiesQuery();

  const fetchState = async () => {
    const param = {
      country: Constants?.COUNTRY_FILTER[data?.country?.toUpperCase()],
    };
    const result = await getStates(param);
    if (result?.data?.success && result?.data?.data && result?.data?.data?.length > 0) {
      const stateData = result?.data?.data?.map((item) => ({
        label: item?.name,
        value: item?.iso,
      }));
      setStateArray(stateData);
    } else {
      setStateArray([]);
    }
  };

  const fetchCity = async () => {
    const param = {
      country: Constants?.COUNTRY_FILTER[data?.country?.toUpperCase()],
      state: data?.state?.toUpperCase(),
    };
    const result = await getCities(param);
    if (result?.data?.success && result?.data?.data && result?.data?.data?.length > 0) {
      const cityData = result?.data?.data?.map((item) => ({
        label: item,
        value: item,
      }));
      setCityArray(cityData);
    } else {
      setCityArray([]);
    }
  };

  useEffect(() => {
    if (data?.country) {
      fetchState();
    }
    if (data?.state) {
      fetchCity();
    }
  }, [data?.country, data?.state]);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <MDBox pt={3} pb={1}>
      <FormSeparator label={t("store:BASIC_DETAILS")} />
      <Grid container spacing={3} pt={2} pb={4}>
        <Grid item xs={6} sm={4}>
          <TextField
            fullWidth
            id="name"
            label={t("store:STORE_NAME")}
            name="name"
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            value={data?.name}
            error={error?.name?.length > 0}
            helperText={error?.name}
            disabled={isViewOnly}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            fullWidth
            id="owner_name"
            label={t("store:OWNER_NAME")}
            name="owner_name"
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            value={data?.owner_name}
            error={error?.owner_name?.length > 0}
            helperText={error?.owner_name}
            disabled={isViewOnly}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            fullWidth
            id="email"
            label={t("common:EMAIL")}
            name="email"
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            value={data?.email}
            error={error?.email?.length > 0}
            helperText={error?.email}
            disabled={isViewOnly}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            id="phone"
            label={t("common:PHONE")}
            name="phone"
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            value={data?.phone}
            error={error?.phone?.length > 0}
            helperText={error?.phone}
            disabled={isViewOnly}
          />
        </Grid>
        {!data?.id && (
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              id="password"
              label={t("common:PASSWORD")}
              name="password"
              type={showPassword ? "text" : "password"}
              onBlur={onBlurHandler}
              onChange={onChangeHandler}
              value={data?.password}
              error={error?.password?.length > 0}
              helperText={error?.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
      </Grid>
      <FormSeparator label={t("store:STORE_ADDRESS")} />
      <Grid container spacing={3} pt={2} pb={4}>
        <Grid item xs={12} sm={8}>
          <TextField
            id="address"
            label={t("store:STREET_ADDRESS")}
            fullWidth
            rows={2}
            name="address"
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            value={data?.address}
            error={error?.address?.length > 0}
            helperText={error?.address}
            disabled={isViewOnly}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label={t("store:PINCODE")}
            id="pincode"
            type="number"
            fullWidth
            name="pincode"
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            value={data?.pincode}
            error={error?.pincode?.length > 0}
            helperText={error?.pincode}
            disabled={isViewOnly}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <CountryDropDown
            value={data?.country}
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            isViewOnly={isViewOnly}
          />
          {error?.country?.length > 0 && <FormHelperText error>{error?.country}</FormHelperText>}
        </Grid>
        <Grid item xs={6} sm={4}>
          <DropDown
            label={t("store:STATE")}
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            value={data?.state}
            isViewOnly={isViewOnly}
            option={stateArray}
          />
          {error?.state?.length > 0 && <FormHelperText error>{error?.state}</FormHelperText>}
        </Grid>
        <Grid item xs={6} sm={4}>
          <DropDown
            label={t("store:CITY")}
            onBlur={onBlurHandler}
            onChange={onChangeHandler}
            value={data?.city}
            isViewOnly={isViewOnly}
            option={cityArray}
          />
          {error?.city?.length > 0 && <FormHelperText error>{error?.city}</FormHelperText>}
        </Grid>
      </Grid>
      <Grid container spacing={5} pt={2}>
        <Grid item xs={12} sm={4}>
          <FormSeparator label={t("store:DELIVERY_DETAILS")} />
          <Grid container spacing={3} pt={2}>
            <Grid item xs={12}>
              <FormControl
                error={error?.delivery_options?.length > 0}
                fullWidth
                style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
              >
                <FormLabel
                  className={`checkbox-form-label ${
                    error?.delivery_options?.length > 0 ? "" : "checkbox-form-color"
                  }`}
                >
                  {t("store:DELIVERY_OPTIONS")}:
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data?.delivery_options?.pickup}
                        onChange={(e) => handleCheckboxChange(e, "delivery_options")}
                        name="pickup"
                        disabled={isViewOnly}
                      />
                    }
                    label={<span className="checkbox-label">{t("store:PICK_UP")}</span>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data?.delivery_options?.delivery}
                        onChange={(e) => handleCheckboxChange(e, "delivery_options")}
                        name="delivery"
                        disabled={isViewOnly}
                      />
                    }
                    label={<span className="checkbox-label">{t("store:DELIVERY")}</span>}
                  />
                </FormGroup>
                <FormHelperText>{error?.delivery_options}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("store:DELIVERY_TIME")}
                id="delivery-time"
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="input-post-fix-label" position="end">
                      {t("store:MIN")}
                    </InputAdornment>
                  ),
                }}
                name="delivery_time"
                onBlur={onBlurHandler}
                onChange={onChangeHandler}
                value={data?.delivery_time}
                error={error?.delivery_time?.length > 0}
                helperText={error?.delivery_time}
                disabled={isViewOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("store:DELIVERY_CHARGE")}
                id="delivery-charge"
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="input-post-fix-label" position="end">
                      $
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                name="delivery_charge"
                onBlur={onBlurHandler}
                onChange={onChangeHandler}
                value={data?.delivery_charge}
                error={error?.delivery_charge?.length > 0}
                helperText={error?.delivery_charge}
                disabled={isViewOnly}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormSeparator label={t("store:PAYMENT_DETAILS")} />
          <Grid container spacing={3} pt={2}>
            <Grid item xs={12}>
              <FormControl
                error={error?.payment_options?.length > 0}
                fullWidth
                style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
              >
                <FormLabel
                  className={`checkbox-form-label ${
                    error?.payment_options?.length > 0 ? "" : "checkbox-form-color"
                  }`}
                >
                  {t("store:PAYMENT_OPTIONS")}:
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data?.payment_options?.cod}
                        onChange={(e) => handleCheckboxChange(e, "payment_options")}
                        name="cod"
                        disabled={isViewOnly}
                      />
                    }
                    label={<span className="checkbox-label">{t("store:COD")}</span>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={data?.payment_options?.card}
                        onChange={(e) => handleCheckboxChange(e, "payment_options")}
                        name="card"
                        disabled={isViewOnly}
                      />
                    }
                    label={<span className="checkbox-label">{t("store:CARD")}</span>}
                  />
                </FormGroup>
                <FormHelperText>{error?.payment_options}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Pick-up Time"
                id="pick-up-time"
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="input-post-fix-label" position="end">
                      {t("store:MIN")}
                    </InputAdornment>
                  ),
                }}
                name="pickup_time"
                onBlur={onBlurHandler}
                onChange={onChangeHandler}
                value={data?.pickup_time}
                error={error?.pickup_time?.length > 0}
                helperText={error?.pickup_time}
                disabled={isViewOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("store:GST_TAX")}
                id="gst-tax"
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="input-post-fix-label" position="end">
                      %
                    </InputAdornment>
                  ),
                }}
                name="tax"
                onBlur={onBlurHandler}
                onChange={onChangeHandler}
                value={data?.tax}
                error={error?.tax?.length > 0}
                helperText={error?.tax}
                disabled={isViewOnly}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormSeparator label={t("store:GLUTEN_FREE_DETAILS")} />
          <Grid container spacing={2} pt={2}>
            <Grid item xs={12}>
              <TextField
                label={t("store:LARGE_PIZZA")}
                name="large"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="input-post-fix-label" position="end">
                      $
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                value={data?.gluten_free_price?.large}
                error={error?.gluten_free_price?.large?.length > 0}
                helperText={error?.gluten_free_price?.large}
                onChange={onGlutenChange}
                disabled={isViewOnly}
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("store:SMALL_PIZZA")}
                name="small"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="input-post-fix-label" position="end">
                      $
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                value={data?.gluten_free_price?.small}
                error={error?.gluten_free_price?.small?.length > 0}
                helperText={error?.gluten_free_price?.small}
                onChange={onGlutenChange}
                disabled={isViewOnly}
                type="number"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MDBox>
  );
};

export default BasicDetail;
