import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import colors from "../../assets/theme/base/colors";

const Loader = ({ open }) => {
  return (
    <Backdrop
      sx={{ color: colors.info.main, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
