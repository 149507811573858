import { MuiFileInput } from "mui-file-input";

const FileInput = ({ handleFileSelect, selectedFiles, multiple, label, error, fileTypes }) => {
  const validateFileType = (file) => {
    const fileType = file?.name?.substring(file?.name?.lastIndexOf("."));
    return fileTypes.includes(fileType);
  };

  const handleInputChange = (files) => {
    if (!files) {
      handleFileSelect(null);
    } else if (multiple) {
      const filteredFiles = Array.from(files).filter(validateFileType);
      handleFileSelect(filteredFiles);
    } else if (validateFileType(files)) {
      handleFileSelect(files);
    }
  };

  return (
    <MuiFileInput
      label={label || "Select or drop a file"}
      onChange={handleInputChange}
      value={selectedFiles}
      multiple={multiple}
      fullWidth
      error={error?.length > 0}
      helperText={error}
      inputProps={{
        accept: fileTypes.join(","),
      }}
    />
  );
};

export default FileInput;
