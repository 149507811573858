// @mui material components
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Loader from "components/Loader";
// React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";

// import dummyData from "lib/dummyData";
import { useTranslation } from "react-i18next";
import { useLazyGetUserQuery } from "../../services/user.service";

function UserList() {
  const { t } = useTranslation();
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(false);

  const [getUser] = useLazyGetUserQuery();

  const fetchUsers = async () => {
    setLoading(true);
    const { data, isSuccess, isError, error } = await getUser();
    if (isSuccess) {
      setUsers(data?.data);
    }
    setLoading(false);
    if (isError) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const columns = [
    { Header: t("common:NAME"), accessor: "user", width: "45%", align: "left" },
    { Header: t("common:email"), accessor: "email", width: "45%", align: "left" },
    { Header: t("common:PHONE"), accessor: "phoneNumber", align: "left" },
  ];

  const rows = users?.map((user) => ({
    user: (
      <MDTypography display="block" variant="button" fontWeight="medium">
        {user?.name}
      </MDTypography>
    ),
    email: <MDTypography variant="caption">{user?.email}</MDTypography>,
    phoneNumber: <MDTypography variant="caption">{user?.mobile}</MDTypography>,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                bgColor="#EC643C"
                borderRadius="lg"
                coloredShadow="#"
              >
                <MDTypography variant="h6" color="white">
                  Users Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows && rows?.length !== 0 && (
                  <DataTable
                    sx={{ textAlign: "center" }}
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Loader open={loading} />
    </DashboardLayout>
  );
}

export default UserList;
