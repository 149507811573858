import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import commonEN from "locales/en/common.json";
import storeEN from "locales/en/store.json";
import signEN from "locales/en/signin.json";
import orderEN from "locales/en/order.json";
import productEN from "locales/en/product.json";
import transactionEN from "locales/en/transaction.json";
import categoriesEN from "locales/en/productCategories.json";
import ingredientsEN from "locales/en/ingredients.json";

const resources = {
  en: {
    common: commonEN,
    store: storeEN,
    signIn: signEN,
    order: orderEN,
    product: productEN,
    transaction: transactionEN,
    categories: categoriesEN,
    ingredients: ingredientsEN,
  },
};

// i18N Initialization
i18n.use(initReactI18next).init({
  resources,
  lng: "en", // default language
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export const t = i18n.t.bind(i18n);
export default i18n;
