// React layouts
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Stores from "layouts/stores";

// @mui icons
import {
  TableView,
  ReceiptLong,
  Notifications as NotificationsIcon,
  Person,
  Assignment,
  LocalPizza,
  Category,
} from "@mui/icons-material";
import Products from "layouts/products";
import AddProduct from "layouts/products/AddProduct";

import Icon from "@mui/material/Icon";
import Ingredients from "layouts/ingredients";
import OrderList from "layouts/orderList";
import UserList from "layouts/userList";
import TransactionsList from "layouts/transactions";
import ProductsCategories from "layouts/productCategory";

const routes = [
  // {
  //   type: "collapse",
  //   name: "Dashboard",
  //   key: "dashboard",
  //   icon: <Icon fontSize="small">dashboard</Icon>,
  //   route: "/dashboard",
  //   component: <Dashboard />,
  // },
  {
    type: "invisible",
    name: "Tables",
    key: "tables",
    icon: <TableView fontSize="small" />,
    route: "/tables",
    component: <Tables />,
  },
  {
    type: "invisible",
    name: "Billing",
    key: "billing",
    icon: <ReceiptLong fontSize="small" />,
    route: "/billing",
    component: <Billing />,
  },
  {
    type: "invisible",
    name: "Notifications",
    key: "notifications",
    icon: <NotificationsIcon fontSize="small" />,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    type: "invisible",
    name: "Profile",
    key: "profile",
    icon: <Person fontSize="small" />,
    route: "/profile",
    component: <Profile />,
    isStoreMenu: true,
  },
  {
    type: "invisible",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: <SignIn />,
  },
  {
    type: "invisible",
    name: "Sign Up",
    key: "sign-up",
    icon: <Assignment fontSize="small" />,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
  {
    type: "collapse",
    name: "Stores",
    key: "stores",
    icon: <Icon fontSize="small">store</Icon>,
    route: "/stores",
    component: <Stores />,
    protected: true,
    isStoreMenu: false,
  },
  {
    type: "collapse",
    name: "Categories",
    key: "categories",
    icon: <Category fontSize="small" />,
    route: "/categories",
    component: <ProductsCategories />,
    protected: true,
    isStoreMenu: true,
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    icon: <LocalPizza fontSize="small" />,
    route: "/products",
    component: <Products />,
    protected: true,
    isStoreMenu: true,
  },
  {
    type: "",
    name: "Add Product",
    key: "add-product",
    route: "/products/add",
    component: <AddProduct />,
    protected: true,
    isStoreMenu: false,
  },
  {
    type: "invisible",
    name: "Edit",
    key: "edit-store",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/products/edit/:productId",
    component: <AddProduct />,
    protected: false,
    isStoreMenu: false,
  },
  {
    type: "invisible",
    name: "View",
    key: "view-store",
    icon: <Icon fontSize="small">visibility</Icon>,
    route: "/products/view/:productId",
    component: <AddProduct />,
    protected: true,
    isStoreMenu: true,
  },
  {
    type: "collapse",
    name: "Ingredients",
    key: "ingredients",
    icon: <Icon fontSize="small">soup_kitchen</Icon>,
    route: "/ingredients",
    component: <Ingredients />,
    protected: true,
    isStoreMenu: false,
  },
  {
    type: "invisible",
    name: "Add",
    key: "add-store",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/stores/add",
    component: <Profile />,
    protected: true,
    isStoreMenu: false,
  },
  {
    type: "invisible",
    name: "Edit",
    key: "edit-store",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/stores/edit/:storeId",
    component: <Profile />,
    protected: false,
    isStoreMenu: false,
  },
  {
    type: "invisible",
    name: "View",
    key: "view-store",
    icon: <Icon fontSize="small">visibility</Icon>,
    route: "/stores/view/:storeId",
    component: <Profile />,
    protected: true,
    isStoreMenu: false,
  },
  {
    type: "collapse",
    name: "Order List",
    key: "order-list",
    icon: <Icon fontSize="small">shopping_cart_checkout</Icon>,
    route: "/order-list",
    component: <OrderList />,
    protected: true,
    isStoreMenu: true,
  },
  {
    type: "collapse",
    name: "User List",
    key: "user-list",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/user-list",
    component: <UserList />,
    protected: true,
    isStoreMenu: false,
  },
  {
    type: "collapse",
    name: "Transaction List",
    key: "transaction-list",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/transaction-list",
    component: <TransactionsList />,
    protected: true,
    isStoreMenu: true,
  },
];

export default routes;
