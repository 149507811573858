import baseApiService from "./base-api.service";

const INGREDIENT_TAG = "INGREDIENT";

/**
 * Service to fetch data from the API for an endpoint.
 */
const IngredientApi = baseApiService
  .enhanceEndpoints({
    addTagTypes: [INGREDIENT_TAG],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getIngredients: build.query({
        query: (params) => ({
          url: "/ingrediant",
          params: params,
        }),

        providesTags: [INGREDIENT_TAG],
      }),
      addIngredient: build.mutation({
        query: (data) => ({
          url: "/ingrediant",
          method: "POST",
          body: data,
        }),
      }),
      updateIngredient: build.mutation({
        query: (data) => ({
          url: `/ingrediant/${data.id}`,
          method: "PATCH",
          body: data,
        }),
      }),
      deleteIngredient: build.query({
        query: (id) => ({
          url: `/ingrediant/${id}`,
          method: "DELETE",
        }),
      }),
    }),
    overrideExisting: false,
  });

export const {
  useLazyGetIngredientsQuery,
  useAddIngredientMutation,
  useUpdateIngredientMutation,
  useLazyDeleteIngredientQuery,
} = IngredientApi;
