import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import FormButtons from "components/FormButtons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ChangePasswordDialog = ({
  dialogOpen,
  handleDialogClose,
  onBlurHandler,
  onSubmitHandler,
  error,
}) => {
  const { t } = useTranslation();

  const passwordFields = {
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  };

  const [showPassword, setShowPassword] = useState(passwordFields);

  const handleClickShowPassword = (passwordType) => {
    showPassword[passwordType] = !showPassword[passwordType];
    setShowPassword({ ...showPassword });
  };

  return (
    <Dialog open={dialogOpen} maxWidth="xs" fullWidth>
      <DialogTitle>{t("common:CHANGE_PASSWORD")}</DialogTitle>
      <DialogContent>
        <TextField
          id="outlined-password-input"
          autoComplete="current-password"
          type={showPassword.currentPassword ? "text" : "password"}
          name="currentPassword"
          fullWidth
          label={t("signIn:CURRENT_PASSWORD")}
          error={error?.currentPassword?.length > 0}
          helperText={error?.currentPassword}
          onBlur={onBlurHandler}
          style={{ marginBottom: "15px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("currentPassword")}
                >
                  {showPassword.currentPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="outlined-password-input"
          autoComplete="current-password"
          type={showPassword.newPassword ? "text" : "password"}
          name="newPassword"
          fullWidth
          label={t("signIn:NEW_PASSWORD")}
          error={error?.newPassword?.length > 0}
          helperText={error?.newPassword}
          onBlur={onBlurHandler}
          style={{ margin: "15px 0" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("newPassword")}
                >
                  {showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          id="outlined-password-input"
          autoComplete="current-password"
          type={showPassword.confirmPassword ? "text" : "password"}
          name="confirmPassword"
          fullWidth
          label={t("signIn:CONFIRM_PASSWORD")}
          error={error?.confirmPassword?.length > 0}
          helperText={error?.confirmPassword}
          onBlur={onBlurHandler}
          style={{ marginTop: "15px" }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleClickShowPassword("confirmPassword")}
                >
                  {showPassword.confirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <FormButtons
        cancelLabel={t("common:CANCEL")}
        submitLabel={t("common:CONFIRM")}
        submitHandler={onSubmitHandler}
        cancelHandler={handleDialogClose}
      />
    </Dialog>
  );
};

export default ChangePasswordDialog;
