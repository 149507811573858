import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  TextField,
  IconButton,
  Switch,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  TablePagination,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDButton from "components/MDButton";
import {
  useLazyGetIngredientsQuery,
  useAddIngredientMutation,
  useUpdateIngredientMutation,
  useLazyDeleteIngredientQuery,
} from "services/ingedient.service";
import DataTable from "../../examples/Tables/DataTable";
import { handleChange, validate } from "../../lib/joiHelper";
import ingredientSchema from "./schema";
import Loader from "../../components/Loader";
import Constants from "../../lib/Constants";
import ConfirmationDialog from "../../components/ConfirmationDialog";

function Ingredients() {
  const initialValues = {
    name: "",
    price: "",
    category: "",
  };
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [ingredients, setIngredients] = useState({
    total: 0,
    data: [],
  });
  const [formData, setFormData] = useState({ ...initialValues });
  const [formError, setFormError] = useState({ ...initialValues });
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteIngredientId, setDeleteIngredientId] = useState("");

  const schema = ingredientSchema(t);

  const [getIngredients] = useLazyGetIngredientsQuery();
  const [addIngredientTrigger] = useAddIngredientMutation();
  const [updateIngredientTrigger] = useUpdateIngredientMutation();
  const [deleteIngredient] = useLazyDeleteIngredientQuery();

  const fetchIngredients = useCallback(async () => {
    setLoading(true);
    const { data, isSuccess, isError, error } = await getIngredients({
      search,
      page: page + 1,
      limit: rowsPerPage,
    });

    if (isSuccess) {
      setIngredients(data);
    }

    if (isError) {
      console.log(error);
    }
    setLoading(false);
  });

  useEffect(() => {
    fetchIngredients();
  }, []);

  useEffect(() => {
    fetchIngredients();
  }, [search, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));

    setPage(0);
  };

  const onChangeHandler = (e) => {
    const { dataClone, errorClone } = handleChange(e, schema, formData, formError);
    setFormData(dataClone);
    setFormError(errorClone);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFormData({ ...initialValues });
    setFormError({ ...initialValues });
    setOpen(false);
  };

  const onSubmitHandler = async () => {
    const errorClone = validate(formData, schema);
    if (errorClone) {
      setFormError(errorClone);
    } else {
      let result;
      if (formData.id) {
        result = await updateIngredientTrigger(formData);
      } else {
        result = await addIngredientTrigger(formData);
      }

      if ("error" in result) {
        toast.error(result?.error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
      } else {
        toast.success(result?.data?.msg);
        handleClose();
        await fetchIngredients();
      }
    }
  };

  const onEditHandler = (data) => {
    setOpen(true);
    setFormData({
      id: data.id,
      name: data.name,
      category: data.category,
      price: data.price,
    });
  };

  const handleEnableDisable = async (ingredientId, ingredientStatus) => {
    const updatedStatus =
      ingredientStatus === Constants.INGRIDIENT_STATUS.ENABLED
        ? Constants.INGRIDIENT_STATUS.DISABLED
        : Constants.INGRIDIENT_STATUS.ENABLED;

    const ingedientsClone = { ...ingredients };

    ingedientsClone.data = ingedientsClone?.data?.map((ingredient) => {
      if (ingredient?.id === ingredientId) {
        ingredient = { ...ingredient, status: updatedStatus };
      }
      return ingredient;
    });
    setIngredients(ingedientsClone);

    const result = await updateIngredientTrigger({
      status: updatedStatus,
      id: ingredientId,
    });
    if ("error" in result) {
      toast.error(result?.error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
    } else {
      toast.success(result?.data?.msg);
    }
  };

  const handleDialogOpen = (id) => {
    setDeleteIngredientId(id);
    setDeleteDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirm = async () => {
    setDeleteDialogOpen(false);
    const { data, isSuccess, isError, error, isLoading } = await deleteIngredient(
      deleteIngredientId
    );
    setLoading(isLoading);
    if (isSuccess) {
      toast.success(data?.msg);
      fetchIngredients();
    }
    if (isError) {
      toast.error(error?.data?.message || t("common:SOMETHING_WENT_WRONG"));
    }
  };

  const columns = [
    { Header: "name", accessor: "name", width: "35%", align: "left" },
    { Header: "category", accessor: "category", align: "left" },
    { Header: "price", accessor: "price" },
    { Header: "enable", accessor: "enable", align: "center" },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = ingredients?.data?.map((item) => ({
    name: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {item.name}
      </MDTypography>
    ),
    category: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {item?.category}
      </MDTypography>
    ),
    price: (
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {item.price}
      </MDTypography>
    ),
    enable: (
      <Switch
        inputProps={{ "aria-label": "Enable Product" }}
        value="1"
        color="warning"
        checked={item?.status === Constants.INGRIDIENT_STATUS.ENABLED}
        onChange={() => handleEnableDisable(item?.id, item?.status)}
      />
    ),
    action: (
      <MDTypography variant="caption" fontWeight="medium">
        <IconButton aria-label="edit" color="secondary" onClick={() => onEditHandler(item)}>
          <EditIcon />
        </IconButton>
        <IconButton
          aria-label="delete"
          color="secondary"
          onClick={() => handleDialogOpen(item?.id)}
        >
          <DeleteIcon />
        </IconButton>
      </MDTypography>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ConfirmationDialog
        title={t("ingredients:DELETE_INGREDIENT")}
        content={t("common:ARE_YOU_SURE_YOU_WANT_TO_DELETE")}
        confirmText={t("common:CONFIRM")}
        cancelText={t("common:CANCEL")}
        open={deleteDialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleConfirm}
      />
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle>
          {formData.id ? t("ingredients:UPDATE_INGREDIENT") : t("ingredients:ADD_INGREDIENT")}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="ingredient"
            name="name"
            value={formData.name}
            label={t("common:NAME")}
            type="text"
            fullWidth
            variant="outlined"
            error={formError?.name?.length > 0}
            helperText={formError?.name}
            onChange={onChangeHandler}
          />

          <FormControl fullWidth sx={{ marginTop: "12px" }}>
            <InputLabel id="demo-simple-select-label">{t("ingredients:CATEGORY")}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              value={formData?.category}
              label={t("ingredients:CATEGORY")}
              name="category"
              onChange={onChangeHandler}
              sx={{ height: 43 }}
            >
              {Constants.INGREDIENTS_CATEGORIES.map((category) => (
                <MenuItem key={category.value} value={category.value}>
                  {category.label}
                </MenuItem>
              ))}
            </Select>
            {formError?.category?.length > 0 && (
              <FormHelperText error>{formError?.category}</FormHelperText>
            )}
          </FormControl>
          <TextField
            margin="dense"
            id="ingredient"
            name="price"
            value={formData.price}
            label={t("common:PRICE")}
            type="number"
            fullWidth
            variant="outlined"
            error={formError?.price?.length > 0}
            helperText={formError?.price}
            onChange={onChangeHandler}
            style={{ marginTop: "15px" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {t("common:CANCEL")}
          </Button>
          <MDButton onClick={onSubmitHandler} variant="gradient" color="info" size="small">
            {formData.id ? t("common:UPDATE") : t("common:ADD")}
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <MDTypography variant="h6" color="white">
                  {t("ingredients:INGREDIENTS")}
                </MDTypography>
                <MDButton onClick={handleClickOpen} color="white" size="small">
                  <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                  &nbsp;{t("ingredients:ADD_NEW_INGREDIENT")}
                </MDButton>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  showTotalEntries
                  canSearch
                  noEndBorder
                  onSearchHandler={(value) => setSearch(value)}
                />
                <TablePagination
                  rowsPerPageOptions={Constants.ROWS_PER_PAGE_OPTIONS}
                  component="div"
                  count={ingredients?.total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Loader open={loading} />
    </DashboardLayout>
  );
}

export default Ingredients;
