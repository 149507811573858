import { Divider, Box } from "@mui/material";

const FormSeparator = ({ label }) => {
  const dividerWidth = { xs: "80%", md: "80%" };
  const dividerHeight = "4px";
  const dividerMarginBottom = { xs: "10px", md: "0" };
  const boxTextAlign = { xs: "center", md: "center" };
  const boxMarginX = { xs: "auto", md: "auto" };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ textAlign: boxTextAlign }}>
        <span>{label}</span>
        <Divider
          sx={{
            width: dividerWidth,
            height: dividerHeight,
            mx: boxMarginX,
            mb: dividerMarginBottom,
          }}
        />
      </Box>
    </Box>
  );
};

export default FormSeparator;
